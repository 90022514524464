<template>
  <LoginBody
    v-if="showLoginForm"
    :leadRequestFlow="true"
    @login="loginSuccess"
    @show-registration="showRegistration"
  />
  <ClientConfirmation
    v-else-if="showClientConfirmationsForm"
    :leadData="leadRequest"
    @registered-client-id="registeredClientId"
  />
  <Container v-else-if="showClientQuoteBuilder">
    <CardSection v-if="leadRequest.lead_request_quote_created == '1'">
      <card>
        <template #title>A quote has already been created for this project</template>
        <p>To create a new quote, please import a new project</p>
      </card>
    </CardSection>
    <LeadRequestQuote :leadRequest="leadRequest" />
  </Container>

  <Section v-else style="height: auto" class="p-0">
    <Container v-if="!checked">
      <CardSection>
        <Danger v-if="!loading">
          <template #icon>
            <font-awesome-icon :icon="['fas', 'robot']" />
          </template>
          <template #title>{{ l('Are you a human?') }}</template>

          <p>{{ l('Please confirm that you are a human by clicking the checkbox below!') }}</p>

          <CardList>
            <CardListField>
              <span>
                {{ l('Click checkbox to finalize your request') }}
              </span>

              <div>
                <font-awesome-icon icon="arrow-right" />
                <LoadingIndicator v-if="!showCheckbox" />
                <Checkbox class="info inline" v-else v-model="checked" />
              </div>
            </CardListField>
          </CardList>
        </Danger>
        <div class="flex items-center justify-center p-5" v-else>
          <LoadingIndicator />
        </div>
      </CardSection>

      <CardSection>
        <template #title>{{ l('Why do we do this?') }}</template>
        <p>
          {{
            l(
              'The web is full of bots and spiders that fill out forms automatically. Most forms will receive on average 10-15 per day.  Some can receive as many as 10-15 per minute. Having a lot of junk requests hurts our ability to respond to real requests and causes a lot of extra work to delete. We appreciate your help in fighting the spam!'
            )
          }}
        </p>
      </CardSection>
    </Container>
    <Container v-else>
      <spinner :loading="loading" :size="'3em'" class="scroll-trigger-container" />
      <div v-show="!loading">
        <CardSection v-if="leadRequest && leadRequest.lead_request_quote_created == 1">
          <card>
            <template #title>{{
              l('The lead request has been already used for a quote')
            }}</template>
            <p>{{ l('Please start creating a new quote from source website') }}</p>
          </card>
        </CardSection>

        <template v-else-if="showSelectCompany && companies.length">
          <h3 class="text-center md-2 text-info">
            <font-awesome-icon icon="shop" />
            {{ l('Select Your company') }}
          </h3>
          <div class="lead-request--companies">
            <SuggestionButton
              class="info"
              v-for="company in companies"
              @click="goCompany(company.route)"
              :key="company.company_id"
            >
              <font-awesome-icon icon="shop" />
              <span>
                {{ company.company_name_short }}
              </span>
            </SuggestionButton>
          </div>
        </template>
      </div>
    </Container>
  </Section>
</template>

<script>
import UserScope from '../mixins/UserScope'
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import LoginBody from './LoginBody.vue'
import ClientConfirmation from '../LeadRequest/ClientConfirmation.vue'
import LeadRequestQuote from '../bodies/LeadRequestQuote.vue'
import SuggestionButton from '../ui/SuggestionButton.vue'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'
import Section from '../ui/Section.vue'

export default {
  mixins: [UserScope, TranslationMixin],
  components: {
    LoadingIndicator,
    LoginBody,
    ClientConfirmation,
    LeadRequestQuote,
    SuggestionButton,
    Section
  },

  data() {
    return {
      showCheckbox: 0,
      checked: 0,
      getLeadRequestLoading: false,
      leadRequest: {},
      showSelectCompany: false,
      showLoginForm: false,
      showRegistrationForm: false,
      showClientConfirmationsForm: false,
      showClientQuoteBuilder: false
    }
  },

  computed: {
    loading() {
      return this.getLeadRequestLoading
    },
    companies() {
      return this.$store.state.session.scopableObjects.company || []
    },
    getMetaSource() {
      if (
        !this.leadRequest ||
        !this.leadRequest.oReference ||
        !this.leadRequest.oReference.format
      ) {
        return null
      }
      return this.leadRequest.oReference.format
    }
  },

  watch: {
    async checked(b) {
      if (b) {
        await this.getLeadRequest()
        await this.handleRoleFlow()
      }
    }
  },

  methods: {
    showRegistration() {
      this.showLoginForm = false
      this.showRegistrationForm = true
    },
    async loginSuccess() {
      this.showLoginForm = false
      this.showRegistrationForm = false

      await this.handleRoleFlow()
    },
    async getLeadRequest() {
      this.getLeadRequestLoading = true

      try {
        const { object } = await this.$store.dispatch('LeadRequest/fetch', {
          id: this.$route.params.id
        })
        this.leadRequest = object
        this.getLeadRequestLoading = false
      } catch (e) {
        await this.$store.dispatch(
          'alert',
          {
            error: true,
            message: e.userMessage || e.message
          },
          { root: true }
        )
        this.getLeadRequestLoading = false

        throw e
      }
    },
    async handleRoleFlow() {
      try {
        if (
          !this.leadRequest.oReference.role ||
          (this.leadRequest.oReference.role !== 'client' &&
            this.leadRequest.oReference.role !== 'company')
        ) {
          throw new Error('Wrong role provided')
        }

        // Flow for client role
        // Client must be logged out
        if (this.leadRequest.oReference.role === 'client') {
          await this.$store.dispatch('logout', { go: false })
          this.clientRoleFlow()
        }

        // Flow for company role
        if (this.leadRequest.oReference.role === 'company') {
          let userLoggedIn = false
          try {
            // Flow for logged in user
            await this.$store.dispatch('getScopableObjects')
            userLoggedIn = true
          } catch (e) {
            // Flow for not logged in user
            console.log('User is not logged in')
            // For company role we show login/register
            this.showLoginForm = true
          }
          if (userLoggedIn) this.companyRoleFlow()
        }
      } catch (e) {
        await this.$store.dispatch(
          'alert',
          {
            error: true,
            message: e.userMessage || e.message
          },
          { root: true }
        )
        this.getLeadRequestLoading = false

        throw e
      }
    },
    companyRoleFlow() {
      if (!this.leadRequest.oReference.company || !this.leadRequest.oReference.company.company_id) {
        this.showSelectCompany = true
        return
      }

      if (this.companies.length === 0) {
        throw new Error('No companies found. Please log in as a contractor')
      }

      const company = this.companies.find(
        (comp) => comp.company_id === String(this.leadRequest.oReference.company.company_id)
      )

      if (!company) {
        throw new Error('Company with id provided in the lead request is not found in your scope')
      }

      this.$router.push(`/${company.route}/leadRequest/${this.$route.params.id}`)
    },
    clientRoleFlow() {
      if (!this.leadRequest.oReference.company || !this.leadRequest.oReference.company.company_id) {
        throw new Error('Wrong lead request. No company_id found.')
      }

      if (this.leadRequest.oReference.client && this.leadRequest.oReference.client.client_id) {
        this.showClientQuoteBuilder = true
      } else {
        this.showClientConfirmationsForm = true
      }
    },
    registeredClientId(clientId) {
      this.leadRequest.oReference.client.client_id = clientId
      this.showClientConfirmationsForm = false
      this.clientRoleFlow()
    },
    goCompany(scopeRoute) {
      this.$router.push(`/${scopeRoute}/leadRequest/${this.$route.params.id}`).catch(() => {})
    }
  },
  async mounted() {
    await c.throttle(() => {}, { delay: 2000 })

    this.showCheckbox = 1
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.lead-request--companies {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  font-size: 1.5em;
  font-weight: 500;
  color: $blue-print-700;
  text-align: center;
  line-height: 1.5;

  > *:not(:last-child) {
    margin-bottom: 0.25em !important;
  }
}
</style>
