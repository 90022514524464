<template>
  <scroll-container>
    <LeadRequestFlow />
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'
import LeadRequestFlow from '../bodies/LeadRequestFlow.vue'

export default {
  mixins: [PageMixin],
  components: { LeadRequestFlow },

  data() {
    return {}
  },

  methods: {}
}
</script>
